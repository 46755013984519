//const React = require("react")


// Logs when the client route changes
exports.onRouteUpdate = ({ location, prevLocation }) => {
  //console.log("new pathname", location.pathname)
  //console.log("old pathname", prevLocation ? prevLocation.pathname : null)

  console.log("%cHi, this is Michael and welcome to my small portfolio. This site was created by Gatsby / React, is hosted on my private github account and automatically deployed to netlify.", "background-color:green;color:white")
}

exports.onInitialClientRender = () => {
    setTimeout(function() {
        //document.getElementById("___loader").style.display = "none"
    }, 1000)
}